body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.react-mde textarea {
  position: absolute;
  top: 48px;
  bottom: 60px;
}

.react-mde .grip {
  visibility: hidden;
}
.mde-header .mde-tabs {
  visibility: hidden;
  width: 0px;
}

.react-pdf__Document,
.buildContent,
.previewContent {
  bottom: 60px;
  position: absolute;
  top: 48px;
  overflow: scroll;
  width: 100%;
}

pre.buildContent {
  padding: 10px;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.previewContent h1,
.previewContent h2,
.previewContent h3 {
  color: #423f37;
}

.previewContent ol > li:before {
  color: #423f37;
  content: counter(ol, decimal) ".";
  counter-increment: ol;
  font-weight: 700;
  margin-right: 0.333em;
  position: absolute;
  right: 100%;
}

.previewContent ul > li:before {
  background-color: #423f37;
  border-radius: 14px 14px 14px 14px;
  content: "";
  height: 6px;
  margin-right: 0.333em;
  margin-top: 0.55em;
  position: absolute;
  right: 100%;
  width: 6px;
}

.previewContent ol,
.previewContent ul,
.previewContent dl {
  margin-left: 2rem;
  padding: 0;
}
.previewContent ol {
  counter-reset: ol;
}
.previewContent li + li,
.previewContent dd + dt {
  margin-top: 0.5em;
}

.previewContent ul > li {
  position: relative;
}

.previewContent ol > li {
  position: relative;
}
.previewContent li {
  list-style: none outside none;
}

.react-pdf__Page {
  position: relative;
  padding-bottom: 3px;
  background-color: #999;
  width: 100%;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: inherit !important;
}

.MuiDialog-paperFullScreen {
  padding-top: 80px;
}

.diff {
  font-size: 12px;
  font-family: sans-serif;
}
